import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import SocialMedia from './SocialMedia/SocialMedia';

import { BLOG_URL, CONTACT_EMAIL } from 'services/constants';
import { ReactComponent as MailIcon } from 'assets/envelope.svg';

import s from './Footer.module.scss';

const Footer = ({ footerWrapper }) => {
  return (
    <footer className={`${footerWrapper} ${s.footer}`}>
      <div className="container">
        <div className={s.flexWrapper}>
          <div className={s.wrapperEmail}>
            <h2 className={s.title}>Get in touch</h2>
            <a href={`mailto:${CONTACT_EMAIL}`} className={s.emailLink}>
              <MailIcon className={s.emailIcon} />

              <span className={s.email}>{CONTACT_EMAIL}</span>
            </a>

            <div className={s.legalLinksWrapper}>
              <Link to="/terms-of-service" className={s.linkTerms}>
                Terms of Service
              </Link>
              <Link to="/privacy-policy" className={s.linkPolicy}>
                Privacy Policy
              </Link>
            </div>
          </div>

          <div className={s.wrapperSocialMedia}>
            <h3 className={s.titleFollow}>Follow us</h3>
            <SocialMedia />
          </div>

          <div className={s.wrapperAbout}>
            <Link to="/about" className={s.linkAbout}>
              About SkinBuddy
            </Link>
            <a href={BLOG_URL} className={s.linkBlog}>
              Blog
            </a>

            <p className={s.copyright}>&#169; 2024 D-Labs FZCO. All rights reserved.</p>
          </div>
        </div>

        <p className={s.copyrightMobile}>&#169; 2024 D-Labs FZCO. All rights reserved.</p>
      </div>
    </footer>
  );
};

Footer.propTypes = { footerWrapper: PropTypes.string };

export default Footer;
