import { Link, useLocation } from 'react-router-dom';
import { memo } from 'react';
import Lottie from 'lottie-react';

import animationBanner from 'assets/animations/lottie_banner.json';

import s from './EntryBanner.module.scss';

const EntryBanner = () => {
  const location = useLocation();

  return (
    <Link to="/skin-profile" state={{ from: location }} className={s.wrapper}>
      <div className={s.wrapperContent}>
        <div className={s.wrapperAnimation}>
          <Lottie animationData={animationBanner} className={s.lottie} />
        </div>

        <div className={s.wrapperInfo}>
          <p className={s.description}>
            Tell us more about your skin to get{' '}
            <span className={s.secondPartDescription}>personalised recommendations</span>
          </p>
          <span className={s.btnCustomise}>Customise now</span>
        </div>
      </div>
    </Link>
  );
};

export default memo(EntryBanner);
