import { ReactComponent as InstagramIcon } from 'assets/icon_instagram.svg';
import { ReactComponent as TiktokIcon } from 'assets/icon_tiktok.svg';
import { ReactComponent as YoutubeIcon } from 'assets/icon_youtube.svg';

import {
  CONTACT_LINK_INSTAGRAM,
  CONTACT_LINK_TIKTOK,
  CONTACT_LINK_YOUTUBE,
} from 'services/constants';

import s from './SocialMedia.module.scss';

const SocialMedia = () => {
  return (
    <ul className={s.list}>
      <li className={s.item}>
        <a
          className={s.link}
          href={CONTACT_LINK_INSTAGRAM}
          target="_blank"
          rel="noreferrer nofollow"
          aria-label="Follow us on Instagram"
        >
          <InstagramIcon className={s.icon} />
        </a>
      </li>
      <li className={s.item}>
        <a
          className={s.link}
          href={CONTACT_LINK_TIKTOK}
          target="_blank"
          rel="noreferrer nofollow"
          aria-label="Discover our TikTok content"
        >
          <TiktokIcon className={s.icon} />
        </a>
      </li>
      <li className={s.item}>
        <a
          className={s.link}
          href={CONTACT_LINK_YOUTUBE}
          target="_blank"
          rel="noreferrer nofollow"
          aria-label="Watch our videos on YouTube"
        >
          <YoutubeIcon className={s.icon} />
        </a>
      </li>
    </ul>
  );
};

export default SocialMedia;
