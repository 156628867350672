import axios from 'axios';

import {
  API_ENDPOINT_ANALYZE,
  API_ENDPOINT_INGREDIENT,
  API_ENDPOINT_PRODUCT,
  API_ENDPOINT_RECOMMEND,
  API_ENDPOINT_SCAN_PRODUCT,
  API_ENDPOINT_SEARCH_QUERY,
  API_ENDPOINT_SHOW_CASE,
} from './constants';
import { API_BASE_URL } from './environment';

export const instance = axios.create({
  baseURL: API_BASE_URL,
});

export const getRecommendProducts = async ({ sb_category, concerns }, pr = [], labels = []) => {
  const { data } = await instance.post(API_ENDPOINT_RECOMMEND, {
    [sb_category]: concerns,
    pr,
    labels,
  });
  return data;
};

export const getProductByIdAndConcerns = async (id, concerns, alternativeIds = []) => {
  const { data } = await instance.post(API_ENDPOINT_PRODUCT, {
    [id]: {
      concerns,
      ids: alternativeIds,
    },
  });
  return data;
};

export const getIngredientInfo = async (ingredientName, concern) => {
  const { data } = await instance.post(API_ENDPOINT_INGREDIENT, {
    [ingredientName]: concern,
  });
  return data.description;
};

export const getSearchProducts = async (filterString, concerns = []) => {
  const { data } = await instance.post(API_ENDPOINT_SEARCH_QUERY, {
    [filterString]: concerns,
  });
  return data;
};

export const getPersonalizedProducts = async concerns => {
  const { data } = await instance.post(API_ENDPOINT_SHOW_CASE, { concerns });
  return data;
};

export const getAnalyzeIngredient = async (
  ingredients,
  concerns = [],
  sb_category = '',
  config = {},
) => {
  let body = { ingredients: [ingredients], concerns };

  if (sb_category) {
    body.category = [sb_category];
  }
  const { data } = await instance.post(API_ENDPOINT_ANALYZE, body, config);
  return data;
};

export const getProductInfoByImage = async (fileBase64 = '', concerns, config = {}) => {
  const { data } = await instance.post(
    API_ENDPOINT_SCAN_PRODUCT,
    { img: [fileBase64], concerns },
    config,
  );
  return data;
};
