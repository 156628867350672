import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { getSearchProducts } from 'services/api';
import { DEBOUNCED_PRODUCT_SEARCH } from 'services/constants';
import { debounce } from 'throttle-debounce';
import { useLocalStorage } from './useLocalStorage';
import { getConcernsSetting } from 'services/utilities';

const SearchProductContext = createContext();

export const SearchProductProvider = ({ children }) => {
  const [filter, setFilter] = useState('');

  return (
    <SearchProductContext.Provider value={{ filter, setFilter }}>
      {children}
    </SearchProductContext.Provider>
  );
};

export const useSearch = () => {
  const { filter, setFilter } = useContext(SearchProductContext);
  const [searchProducts, setSearchProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const isFirstLoading = useRef(true);
  const {
    mainSettings: { skinType, skinConcerns, acneIssues, agingSings, eye },
  } = useLocalStorage();

  const concerns = useMemo(
    () => getConcernsSetting(skinType, [...skinConcerns, ...acneIssues, ...agingSings, ...eye]),
    [acneIssues, agingSings, eye, skinConcerns, skinType],
  );

  useEffect(() => {
    const getSpecificProducts = debounce(DEBOUNCED_PRODUCT_SEARCH, async () => {
      setIsLoading(true);
      try {
        const data = await getSearchProducts(filter, concerns);
        setSearchProducts(data);
      } catch (error) {
        setSearchProducts([]);
      } finally {
        setIsLoading(false);
        isFirstLoading.current = false;
      }
    });

    if (!filter) {
      isFirstLoading.current = true;
    }

    if (filter.trim()) {
      getSpecificProducts();
    }

    return () => {
      if (getSpecificProducts.cancel) {
        getSpecificProducts.cancel();
      }
    };
  }, [concerns, filter, setIsLoading, setSearchProducts]);

  const resetFilter = useCallback(() => {
    isFirstLoading.current = true;
    setFilter('');
    setSearchProducts([]);
  }, [setFilter, setSearchProducts]);

  return {
    filter,
    setFilter,
    searchProducts,
    setSearchProducts,
    isLoading,
    setIsLoading,
    resetFilter,
    isFirstLoading,
  };
};
