export const SKIN_CONCERNS = [
  {
    name: 'Enlarged pores',
    is_selected: false,
    position: 1,
  },
  {
    name: 'Sensitivity',
    is_selected: false,
    position: 2,
  },
  {
    name: 'Dullness',
    is_selected: false,
    position: 3,
  },
  {
    name: 'Hyperpigmentation',
    is_selected: false,
    position: 4,
  },
  {
    name: 'Uneven texture',
    is_selected: false,
    position: 5,
  },
  {
    name: 'Dehydrated skin',
    is_selected: false,
    position: 6,
  },
  {
    name: 'Eczema',
    is_selected: false,
    position: 7,
  },
  {
    name: 'Rosacea',
    is_selected: false,
    position: 8,
  },
];

export const SKIN_CONCERNS_DESCRIPTION = [
  {
    name: 'Enlarged Pores',
    description:
      "These are visible openings on the skin's surface, often appearing larger due to genetics, increased oil production, or aging. Regular cleansing and using products that balance oil production can help minimise their appearance. If pores become problematic, consult a dermatologist.",
    img_name: 'enlarged_pores.png',
  },
  {
    name: 'Sensitivity',
    description:
      "Sensitive skin easily reacts to certain products or environmental factors, causing redness, itching, or burning. Using hypoallergenic and non-irritating skincare products is recommended. Persistent symptoms may require a dermatologist's advice.",
    img_name: 'skin_sensitivity.png',
  },
  {
    name: 'Dullness',
    description:
      "This refers to a lack of radiance or a tired, uneven complexion. It can result from factors like dehydration, poor exfoliation, or environmental stressors. Using hydrating and brightening skincare products can help restore skin's natural glow.",
    img_name: 'dullness.png',
  },
  {
    name: 'Hyperpigmentation',
    description:
      "This involves darkening of certain areas of the skin, often due to sun exposure, hormonal changes, or acne scarring. Using sun protection and skincare products with brightening agents can help. Persistent or severe cases may benefit from a dermatologist's treatment.",
    img_name: 'hyperpigmentation.png',
  },
  {
    name: 'Dehydrated Skin',
    description:
      "This condition is characterised by a lack of moisture in the skin, leading to a tight, uncomfortable feeling, and sometimes flakiness. It's important to hydrate the skin with appropriate moisturisers and drink plenty of water. If the skin remains persistently dry, consider consulting a dermatologist.",
    img_name: 'dehydrated_skin.png',
  },
  {
    name: 'Uneven Texture',
    description:
      "This is when the skin feels rough or bumpy to the touch, often caused by dead skin cells, dryness, or scarring. Regular exfoliation and moisturizing can improve the skin's texture. If the texture is a significant concern, a dermatologist can offer targeted treatments.",
    img_name: 'uneven_texture.png',
  },
  {
    name: 'Eczema',
    description:
      'A condition marked by itchy, inflamed, and red skin. It can be triggered by environmental factors, allergens, or stress. Moisturising and avoiding irritants are key. Persistent or severe eczema should be evaluated by a dermatologist.',
    img_name: 'eczema.png',
  },
  {
    name: 'Rosacea',
    description:
      'This chronic skin condition causes redness, visible blood vessels, and sometimes acne-like bumps, primarily on the face. Triggers vary and can include certain foods, weather, or stress. Managing rosacea often requires specialised skincare and possibly medical treatment from a dermatologist.',
    img_name: 'rosazea.png',
  },
];

export const ACNE_ISSUES = [
  {
    name: 'Acne-prone skin',
    is_selected: false,
    position: 1,
  },
  {
    name: 'Blackheads',
    is_selected: false,
    position: 2,
  },
  {
    name: 'Whiteheads',
    is_selected: false,
    position: 3,
  },
  {
    name: 'Mild acne',
    is_selected: false,
    position: 4,
  },
  {
    name: 'Severe acne',
    is_selected: false,
    position: 5,
  },
  {
    name: 'Acne scars',
    is_selected: false,
    position: 6,
  },
  {
    name: 'Post-acne marks',
    is_selected: false,
    position: 7,
  },
];

export const ACNE_ISSUES_DESCRIPTION = [
  {
    name: 'Acne-prone skin',
    description:
      'Acne-prone skin is characterized by frequent breakouts, including pimples, blackheads, or whiteheads, often triggered by hormonal changes, stress, or certain products. If you regularly experience these issues despite proper cleansing, you likely have acne-prone skin. Effective management involves a routine aimed at balancing oil production and maintaining clear pores.',
    img_name: 'occasional_breakouts.png',
  },
  {
    name: 'Blackheads',
    description:
      "Small, dark lesions that appear on the skin, often on the face and neck. They are a result of clogged hair follicles, where the skin's natural oils and dead skin cells collect. Gentle exfoliation and regular cleansing can help manage blackheads.",
    img_name: 'blackheads.png',
  },
  {
    name: 'Whiteheads',
    description:
      'These are small, flesh-coloured or white bumps caused by clogged pores. Similar to blackheads, they result from oil, dead skin cells, and bacteria blocking the pores. Keeping the skin clean and using non-comedogenic products can be helpful.',
    img_name: 'whiteheads.png',
  },
  {
    name: 'Mild Acne',
    description:
      'Characterised by a few scattered pimples, blackheads, or whiteheads. It can usually be managed with over-the-counter acne treatments and good skin care hygiene. If it persists or worsens, consider consulting a dermatologist.',
    img_name: 'mild_acne.png',
  },
  {
    name: 'Severe Acne',
    description:
      "This involves numerous, persistent pimples, cysts, or nodules, often causing pain and significant skin redness. It's more likely to lead to scarring and requires treatment from a dermatologist.",
    img_name: 'severe_acne.png',
  },
  {
    name: 'Acne Scars',
    description:
      'These are marks left on the skin after acne has healed. They can be in the form of pits or raised scars and are more common after severe acne. Various treatments are available, including over-the-counter products and professional procedures.',
    img_name: 'acne_scars.png',
  },
  {
    name: 'Post Acne Marks',
    description:
      'These are discolorations left on the skin after an acne breakout has healed. They can vary in colour, appearing as red, brown, or even purplish spots, depending on your skin tone and the severity of the breakout. These marks are not scars and usually fade over time, but their visibility can be reduced with topical treatments that promote skin cell turnover, such as retinoids, or with brightening ingredients like vitamin C. If these marks are a significant concern or persist for a long time, consulting a dermatologist for specialised treatments might be beneficial.',
    img_name: 'post_acne_marks.png',
  },
];

export const AGING_SINGS = [
  {
    name: 'Fine lines',
    is_selected: false,
    position: 1,
  },
  {
    name: 'Wrinkles',
    is_selected: false,
    position: 2,
  },
  {
    name: 'Thinning skin',
    is_selected: false,
    position: 3,
  },
  {
    name: 'Loss of firmness',
    is_selected: false,
    position: 4,
  },
  {
    name: 'Age spots',
    is_selected: false,
    position: 5,
  },
  {
    name: 'Decreased elasticity',
    is_selected: false,
    position: 6,
  },
];

export const AGING_SINGS_DESCRIPTION = [
  {
    name: 'Fine Lines',
    description:
      'These are early signs of aging, appearing as small, shallow lines, often around the eyes and mouth. They usually form due to repeated facial expressions and can be more noticeable when the skin is dry.',
    img_name: 'fine_lines.png',
  },
  {
    name: 'Wrinkles',
    description:
      'More pronounced than fine lines, wrinkles are deeper creases in the skin, often found in areas frequently exposed to sun and movement, such as the forehead, around the eyes, and mouth. They develop over time due to factors like sun exposure, lifestyle habits, and natural aging.',
    img_name: 'wrinkles.png',
  },
  {
    name: 'Thinning Skin',
    description:
      "This concern involves the skin becoming more fragile and transparent, often feeling less plump to the touch. It's typically a result of the natural aging process, which reduces the production of collagen and elastin in the skin.",
    img_name: 'thinning_skin.png',
  },
  {
    name: 'Loss of Firmness',
    description:
      "This is noticed when the skin starts to feel less tight and begins to sag. It happens as the skin's natural elasticity decreases with age, leading to a less defined appearance, particularly around the jawline and neck.",
    img_name: 'loss_of_firmness.png',
  },
  {
    name: 'Age Spots',
    description:
      'Often referred to as sun spots, these are small, dark patches on the skin, resulting from long-term sun exposure. They are more common in areas frequently exposed to the sun, like the face, hands, and arms.',
    img_name: 'age_spots.png',
  },
  {
    name: 'Decreased Elasticity',
    description:
      "This refers to the skin's reduced ability to bounce back after being stretched. It can lead to a saggy appearance and is caused by a decrease in the skin's elastin fibers, often due to aging and environmental factors like UV exposure.",
    img_name: 'decreased_elasticity.png',
  },
];

export const EYE_CONCERNS = [
  {
    name: 'Puffiness',
    is_selected: false,
    position: 1,
  },
  {
    name: 'Under-eye sensitivity',
    is_selected: false,
    position: 2,
  },
  {
    name: 'Dark circles',
    is_selected: false,
    position: 3,
  },
  {
    name: 'Dull under-eye skin',
    is_selected: false,
    position: 4,
  },
  {
    name: "Crow's feet",
    is_selected: false,
    position: 5,
  },
  {
    name: 'Under-eye wrinkles',
    is_selected: false,
    position: 6,
  },
  {
    name: 'Sagging eyelids',
    is_selected: false,
    position: 7,
  },
  {
    name: 'Under-eye dryness',
    is_selected: false,
    position: 8,
  },
];

export const EYE_CONCERNS_DESCRIPTION = [
  {
    name: 'Puffiness',
    description:
      'Characterized by swelling or bags under the eyes, often more noticeable upon waking. This can be due to fluid retention, lack of sleep, allergies, or hereditary traits. If the puffiness is persistent, consulting a dermatologist may be beneficial.',
    img_name: 'puffiness.png',
  },
  {
    name: 'Under-eye Sensitivity',
    description:
      'This is when the skin under the eyes reacts easily to products or environmental factors, causing redness, itching, or irritation. It’s important to use gentle, hypoallergenic products, and persistent sensitivity should be evaluated by a dermatologist.',
    img_name: 'under_eye_sensitivity.png',
  },
  {
    name: 'Dark Circles',
    description:
      'These are noticeable shadows or darkening under the eyes, which can be due to various factors including genetics, fatigue, age, or even allergies. They may be accompanied by a hollowed look or a deepening of the natural tear trough.',
    img_name: 'dark_circles.png',
  },
  {
    name: 'Dull under-eye skin',
    description:
      'When the eye area appears lackluster or fatigued, lacking its usual brightness. Often a result of poor sleep, aging, or dehydration. Hydrating and brightening eye products may help, but if dullness persists, it could be worth seeking advice from a dermatologist.',
    img_name: 'eye_dullness.png',
  },
  {
    name: "Crow's Feet",
    description:
      'Fine lines radiating from the outer corners of the eyes, resembling the foot of a crow. Often a result of repeated facial expressions and sun exposure. Cosmetic products may help to minimize their appearance, but persistent or deep lines may benefit from professional dermatological treatments.',
    img_name: 'crows_feet.png',
  },
  {
    name: 'Under-eye wrinkles',
    description:
      "These fine lines and deeper wrinkles form under the eyes, a natural part of aging often accelerated by sun exposure and environmental stressors. Regular use of moisturizing and anti-aging products can help, but for more pronounced wrinkles, a dermatologist's consultation is advisable.",
    img_name: 'under_eye_wrinkles.png',
  },
  {
    name: 'Sagging eyelids',
    description:
      'A loss of firmness in the skin around the eyes, leading to a droopy appearance. This is typically a result of aging and collagen loss. While certain firming creams and serums can offer temporary improvement, persistent sagging might be best addressed by a skincare professional.',
    img_name: 'eye_sagging.png',
  },
  {
    name: 'Under-eye dryness',
    description:
      'Manifests as a tight, uncomfortable sensation with possible flakiness under the eyes. Commonly exacerbated by environmental elements like wind or dry climates. Persistent dryness might require dermatological attention for more targeted treatments.',
    img_name: 'under_eye_dryness.png',
  },
];

export const SERVER_EYE_PROBLEM_LIST = [
  'Eye puffiness',
  'Under eye sensitivity',
  'Under eye dark circles',
  'Eye dullness',
  'Crow feet',
  'Under eye wrinkles',
  'Eye sagging',
  'Under eye dryness',
];

export const SKIN_TYPE = [
  {
    name: 'Normal',
    is_selected: true,
  },
  {
    name: 'Dry',
    is_selected: false,
  },
  {
    name: 'Oily',
    is_selected: false,
  },
  {
    name: 'Combination',
    is_selected: false,
  },
];

export const SB_CATEGORIES = [
  {
    name: 'Cleanser',
    img_name: 'cleanser.png',
    is_selected: false,
  },
  {
    name: 'Moisturizer',
    img_name: 'moisturizer.png',
    is_selected: true,
  },
  {
    name: 'Sun Care',
    img_name: 'sun_care.png',
    is_selected: false,
  },
  { name: 'Serum', img_name: 'serum.png', is_selected: false },
  {
    name: 'Exfoliation',
    img_name: 'exfoliation3.png',
    is_selected: false,
  },
  { name: 'Toner', img_name: 'toner.png', is_selected: false },
  {
    name: 'Makeup remover',
    img_name: 'makeup_remover.png',
    is_selected: false,
  },
  {
    name: 'BB/CC cream',
    img_name: 'bbcc_cream.png',
    is_selected: false,
  },
  {
    name: 'Eye Care',
    img_name: 'eye_care.png',
    is_selected: false,
  },
  { name: 'Mask', img_name: 'mask.png', is_selected: false },
  {
    name: 'Treatment',
    img_name: 'treatment.png',
    is_selected: false,
  },
  { name: 'Face Oil', img_name: 'serum-1.png', is_selected: false },
];

const baseNotAppropriateSkinProblems = [
  'Puffiness',
  'Under-eye sensitivity',
  'Dark circles',
  'Dull under-eye skin',
  "Crow's feet",
  'Under-eye wrinkles',
  'Sagging eyelids',
  'Under-eye dryness',
];

export const NOT_APPROPRIATE_PROBLEMS_BY_CATEGORY = {
  Cleanser: baseNotAppropriateSkinProblems,
  Moisturizer: baseNotAppropriateSkinProblems,
  'Sun Care': baseNotAppropriateSkinProblems,
  Serum: baseNotAppropriateSkinProblems,
  Exfoliation: baseNotAppropriateSkinProblems,
  Toner: baseNotAppropriateSkinProblems,
  'Makeup remover': [
    'Puffiness',
    'Dark circles',
    'Dull under-eye skin',
    "Crow's feet",
    'Under-eye wrinkles',
    'Sagging eyelids',
  ],
  'BB/CC cream': baseNotAppropriateSkinProblems,
  'Eye Care': [
    'Fine lines',
    'Wrinkles',
    'Thinning skin',
    'Loss of firmness',
    'Age spots',
    'Decreased elasticity',
    'Acne-prone skin',
    'Blackheads',
    'Whiteheads',
    'Mild acne',
    'Severe acne',
    'Acne scars',
    'Post-acne marks',
    'Enlarged pores',
    'Sensitivity',
    'Dullness',
    'Hyperpigmentation',
    'Uneven texture',
    'Dehydrated skin',
    'Eczema',
    'Rosacea',
  ],
  Mask: baseNotAppropriateSkinProblems,
  Treatment: baseNotAppropriateSkinProblems,
  'Face Oil': baseNotAppropriateSkinProblems,
};

export const ADDITIONAL = {
  Cleanser: [
    { name: 'Non-comedogenic', is_selected: false },
    { name: 'Non-irritating', is_selected: false },
    { name: 'Hypoallergenic', is_selected: false },
  ],
  Moisturizer: [
    { name: 'Non-comedogenic', is_selected: false },
    { name: 'Non-irritating', is_selected: false },
    { name: 'Hypoallergenic', is_selected: false },
  ],
  'Sun Care': [
    { name: 'Non-comedogenic', is_selected: false },
    { name: 'Non-irritating', is_selected: false },
    { name: 'Hypoallergenic', is_selected: false },
  ],
  Serum: [
    { name: 'Non-comedogenic', is_selected: false },
    { name: 'Non-irritating', is_selected: false },
    { name: 'Hypoallergenic', is_selected: false },
  ],
  Exfoliation: [
    { name: 'Non-comedogenic', is_selected: false },
    { name: 'Non-irritating', is_selected: false },
    { name: 'Hypoallergenic', is_selected: false },
  ],
  Toner: [
    { name: 'Non-comedogenic', is_selected: false },
    { name: 'Non-irritating', is_selected: false },
    { name: 'Hypoallergenic', is_selected: false },
  ],
  'Makeup remover': [
    { name: 'Non-comedogenic', is_selected: false },
    { name: 'Non-irritating', is_selected: false },
    { name: 'Hypoallergenic', is_selected: false },
  ],
  'BB/CC cream': [
    { name: 'Non-comedogenic', is_selected: false },
    { name: 'Non-irritating', is_selected: false },
    { name: 'Hypoallergenic', is_selected: false },
  ],
  'Eye Care': [
    { name: 'Non-irritating', is_selected: false },
    { name: 'Hypoallergenic', is_selected: false },
  ],
  Mask: [
    { name: 'Non-comedogenic', is_selected: false },
    { name: 'Non-irritating', is_selected: false },
    { name: 'Hypoallergenic', is_selected: false },
  ],
  Treatment: [
    { name: 'Non-comedogenic', is_selected: false },
    { name: 'Non-irritating', is_selected: false },
    { name: 'Hypoallergenic', is_selected: false },
  ],
  'Face Oil': [
    { name: 'Non-comedogenic', is_selected: false },
    { name: 'Non-irritating', is_selected: false },
    { name: 'Hypoallergenic', is_selected: false },
  ],
};

export const SB_CATEGORIES_MAP = new Map([
  ['Cleanser', 'Cleanser'],
  ['Moisturizer', 'Moisturiser'],
  ['Sun Care', 'Sun Care'],
  ['Serum', 'Serum'],
  ['Exfoliation', 'Exfoliation'],
  ['Toner', 'Toner'],
  ['Makeup remover', 'Makeup remover'],
  ['BB/CC cream', 'BB/CC cream'],
  ['Eye Care', 'Eye Care'],
  ['Mask', 'Mask'],
  ['Treatment', 'Treatment'],
  ['Face Oil', 'Face oil'],
]);

export const SKIN_CONDITIONS_MAP = new Map([
  ['Dry', 'Dry skin'],
  ['Oily', 'Oily skin'],
  ['Normal', 'Normal skin'],
  ['Combination', 'Combination skin'],

  ['Eczema', 'Eczema'],
  ['Rosacea', 'Rosacea'],
  ['Enlarged pores', 'Enlarged pores'],
  ['Sensitivity', 'Skin sensitivity'],
  ['Dullness', 'Dullness'],
  ['Hyperpigmentation', 'Hyperpigmentation'],
  ['Uneven texture', 'Uneven skin texture'],
  ['Dehydrated skin', 'Dehydrated skin'],

  ['Blackheads', 'Blackheads'],
  ['Whiteheads', 'Whiteheads'],
  ['Acne-prone skin', 'Acne-prone skin'],
  ['Mild acne', 'Mild acne'],
  ['Severe acne', 'Severe acne'],
  ['Acne scars', 'Acne scars'],
  ['Post-acne marks', 'Post acne marks'],

  ['Fine lines', 'Fine lines'],
  ['Wrinkles', 'Wrinkles'],
  ['Loss of firmness', 'Loss of firmness'],
  ['Age spots', 'Age spots'],
  ['Thinning skin', 'Thinning skin'],
  ['Decreased elasticity', 'Loss of elasticity'],

  ['Dark circles', 'Under eye dark circles'],
  ['Puffiness', 'Eye puffiness'],
  ['Under-eye dryness', 'Under eye dryness'],
  ['Under-eye wrinkles', 'Under eye wrinkles'],
  ["Crow's feet", 'Crow feet'],
  ['Eye sagging', 'Eye sagging'],
  ['Sagging eyelids', 'Eye sagging'],
  ['Dull under-eye skin', 'Eye dullness'],
  ['Under-eye sensitivity', 'Under eye sensitivity'],

  ['Dry skin', 'Dry skin'],
  ['Oily skin', 'Oily skin'],
  ['Normal skin', 'Normal skin'],
  ['Combination skin', 'Combination skin'],
  ['Uneven skin texture', 'Uneven texture'],
  ['Post acne marks', 'Post-acne marks'],
  ['Loss of elasticity', 'Decreased elasticity'],
  ['Skin sensitivity', 'Sensitivity'],
  ['Under eye dark circles', 'Dark circles'],
  ['Eye puffiness', 'Puffiness'],
  ['Under eye dryness', 'Under-eye dryness'],
  ['Under eye wrinkles', 'Eye wrinkles'],
  ['Crow feet', "Crow's feet"],
  ['Under eye sensitivity', 'Under-eye sensitivity'],
  ['Eye dullness', 'Dull under-eye skin'],
  ['Eye sagging', 'Sagging eyelids'],

  //
  ['Non-comedogenic', 'NON-COMEDOGENIC'],
  ['Non-irritating', 'NON-IRRITATING'],
  ['Hypoallergenic', 'HYPOALLERGENIC'],
]);

export const PRICE_RANGE = [
  { range: 1, is_selected: false },
  { range: 2, is_selected: false },
  { range: 3, is_selected: false },
  { range: 4, is_selected: false },
];
