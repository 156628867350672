import { useEffect, useState } from 'react';

export const useIsAppleDevice = () => {
  const [isAppleDevice, setIsAppleDevice] = useState(false);

  useEffect(() => {
    const userAgent = typeof navigator === 'undefined' ? '' : navigator.userAgent;
    const isAppleDevice = /iPhone|iPad|iPod/i.test(userAgent);

    setIsAppleDevice(isAppleDevice);
  }, []);

  return isAppleDevice;
};
