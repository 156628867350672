const { createContext, useState, useContext } = require('react');

const PopupContext = createContext();

const PopupProvider = ({ children }) => {
  const [activePopupId, setActivePopupId] = useState(null);
  const [isLoadingInfo, setIsLoadingInfo] = useState(false);

  return (
    <PopupContext.Provider
      value={{ activePopupId, setActivePopupId, isLoadingInfo, setIsLoadingInfo }}
    >
      {children}
    </PopupContext.Provider>
  );
};

const usePopup = () => {
  const context = useContext(PopupContext);

  if (context === undefined) {
    throw new Error('usePopup must be used within a PopupProvider');
  }

  return context;
};

export { PopupProvider, usePopup };
