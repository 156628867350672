import { createPortal } from 'react-dom';
import Lottie from 'lottie-react';

import loadingBubbleAnimation from 'assets/animations/loading_skinbuddy.json';

import s from './LoadingDisplay.module.css';

const portalRef = document.querySelector('#loading-window');

const LoadingDisplay = () => {
  return createPortal(
    <div className={s.overlay}>
      <Lottie animationData={loadingBubbleAnimation} />
    </div>,
    portalRef,
  );
};

export default LoadingDisplay;
