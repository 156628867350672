const getBaseUrl = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'httpsdev':
      return 'https://localhost:3000';
    case 'development':
      return 'http://localhost:3000';
    case 'beta':
      return 'https://beta.skinbuddy.me';
    case 'dev':
      return 'http://localhost:8000';
    default:
      return 'https://skinbuddy.me';
  }
};

const getApiBaseUrl = () => {
  return `${getBaseUrl()}/api/v1`;
};

export const API_BASE_URL = getApiBaseUrl();
export const BASE_URL = getBaseUrl();

/*
If you need to test on cell phones with mocks requests during development replace http://localhost:3000/api/v1 with http://pc_IP:3000/api/v1 and open on PC and on mobile the URL http://pc_IP:3000/api/v1 for test.
*/
