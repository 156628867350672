import { createRef, lazy } from 'react';
import { createBrowserRouter, redirect } from 'react-router-dom';

import Layout from 'components/Layout/Layout';
// import ErrorBoundary from 'components/common/ErrorBoundary/ErrorBoundary';
import { ModalProvider } from 'hooks/useShowModalAndHideScroll';

import { IS_MOBILE_DEVICE } from 'services/constants';

const HomePage = lazy(() => import('pages/HomePage/HomePage'));
const SearchPage = lazy(() => import('pages/SearchPage/SearchPage'));
const ResultsPage = lazy(() => import('pages/ResultsPage/ResultsPage'));
const ProductPage = lazy(() => import('pages/ProductPage/ProductPage'));
const NotFoundPage = lazy(() => import('pages/NotFoundPage/NotFoundPage'));
const FavoritesPage = lazy(() => import('pages/FavoritesPage/FavoritesPage'));
const SkinProfilePage = lazy(() => import('pages/SkinProfilePage/SkinProfilePage'));
const AnalysePage = lazy(() => import('pages/AnalysePage/AnalysePage'));
const IngredientsSummaryPage = lazy(() =>
  import('pages/IngredientsSummaryPage/IngredientsSummaryPage'),
);
const ProductSearchPage = lazy(() => import('pages/ProductSearchPage/ProductSearchPage'));
const AboutPage = lazy(() => import('pages/AboutPage/AboutPage'));
const TermsOfServicePage = lazy(() => import('pages/TermsOfServicePage/TermsOfServicePage'));
const PrivacyPolicyPage = lazy(() => import('pages/PrivacyPolicyPage/PrivacyPolicyPage'));
const ScanProductMobilePage = lazy(() =>
  import('pages/ScanProductMobilePage/ScanProductMobilePage'),
);

const mobileOnlyLoader = () => {
  if (!IS_MOBILE_DEVICE) {
    return redirect('/');
  }
  return null;
};

export const routes = [
  {
    path: '/smart-filter',
    name: 'Search',
    element: <SearchPage />,
    nodeRef: createRef(),
  },
  {
    path: '/results',
    name: 'Results',
    element: <ResultsPage />,
    nodeRef: createRef(),
  },
  {
    path: '/product/:productId',
    name: 'Product',
    element: <ProductPage />,
    nodeRef: createRef(),
  },
  {
    path: '/favorites',
    name: 'Favorites',
    element: <FavoritesPage />,
    nodeRef: createRef(),
  },
  {
    path: '/skin-profile',
    name: 'SkinProfile',
    element: <SkinProfilePage />,
    nodeRef: createRef(),
  },
  {
    path: '/analyse',
    name: 'Analyse',
    element: <AnalysePage />,
    nodeRef: createRef(),
  },
  {
    path: '/ingredients-summary',
    name: 'IngredientsSummary',
    element: <IngredientsSummaryPage />,
    nodeRef: createRef(),
  },
  {
    path: '/product-search',
    name: 'ProductSearch',
    element: <ProductSearchPage />,
    nodeRef: createRef(),
  },
  {
    path: '/about',
    name: 'About',
    element: <AboutPage />,
    nodeRef: createRef(),
  },
  {
    path: '/terms-of-service',
    name: 'TermsOfService',
    element: <TermsOfServicePage />,
    nodeRef: createRef(),
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    element: <PrivacyPolicyPage />,
    nodeRef: createRef(),
  },
  {
    path: '/scan-a-skincare-product',
    name: 'ScanProductMobile',
    element: <ScanProductMobilePage />,
    nodeRef: createRef(),
    loader: mobileOnlyLoader,
  },
  {
    path: '/',
    name: 'Home',
    element: <HomePage />,
    nodeRef: createRef(),
  },
  {
    path: '/*',
    name: 'NotFound',
    element: <NotFoundPage />,
    nodeRef: createRef(),
  },
];

export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      // <ErrorBoundary>
      <ModalProvider>
        <Layout />
      </ModalProvider>
      // </ErrorBoundary>
    ),
    children: routes.map(route => ({
      index: route.path === '/',
      path: route.path === '/' ? undefined : route.path,
      element: route.element,
      loader: route.loader,
    })),
  },
]);
