import { isMobileDevice } from './utilities';

export const CONTACT_EMAIL = 'info@skinbuddy.me';
export const BLOG_URL = 'https://cdn.skinbuddy.me/blog/index.html';

export const LAST_UPDATED_APP_DATA = 'May 2, 2024';

export const APP_ENV_PRODUCTION = 'production';

export const CONTACT_LINK_INSTAGRAM = 'https://www.instagram.com/skinbuddy.me';
export const CONTACT_LINK_YOUTUBE = 'https://www.youtube.com/@Skinbuddy';
export const CONTACT_LINK_TIKTOK = 'https://www.tiktok.com/@skinbuddy.me';

export const LS_USER_DATA_KEY = 'ls-user-data';
export const LS_USER_FIRST_VISIT = 'ls-user-first-visit';

export const API_ENDPOINT_RECOMMEND = '/recommend';
export const API_ENDPOINT_PRODUCT = '/product';
export const API_ENDPOINT_INGREDIENT = '/ingredient';
export const API_ENDPOINT_SEARCH_QUERY = '/search';
export const API_ENDPOINT_SHOW_CASE = '/showcase';
export const API_ENDPOINT_ANALYZE = '/analyze';
export const API_ENDPOINT_SCAN_PRODUCT = '/scan';

export const IMG_BASE_URL = 'https://cdn.skinbuddy.me/';

export const THROTTLE_DELAY_MS_FOR_UP_BUTTON = 200;
export const THROTTLE_WINDOW_RESIZE_DELAY = 300;

export const VISIBILITY_SCROLL_THRESHOLD = 300;
export const DEBOUNCED_PRODUCT_SEARCH = 300;
export const DEBOUNCED_SAVE_TO_LOCAL_STORAGE = 500;

export const COMPONENT_AS_BUTTON = 'btn';
export const COMPONENT_AS_LINK = 'link';
export const COMPONENT_AS_UP_BUTTON = 'upButton';

export const BUTTON_TYPE_BUTTON = 'button';
export const BUTTON_TYPE_SUBMIT = 'submit';

export const CLOSE_BUTTON_WHITE_THEME = 'white';
export const CLOSE_BUTTON_BLACK_THEME = 'black';

export const TITLE_VARIANT_SEARCH = 'search';
export const TITLE_VARIANT_PRODUCT = 'product';
export const TITLE_VARIANT_SETTINGS = 'settings';

export const BUTTON_VARIANT_PRIMARY = 'primary';
export const BUTTON_VARIANT_SECONDARY = 'secondary';
export const BUTTON_VARIANT_TERTIARY = 'tertiary';

export const TITLE_CONCERN_VARIANT_ADVERSE = 'adverse';
export const TITLE_CONCERN_VARIANT_BENEFICIAL = 'beneficial';

export const INPUT_FIELD_TYPE_TEXT = 'text';

export const ORIGIN_LIST_NAME_SHOWCASE = 'showcase';
export const ORIGIN_LIST_NAME_FACETED = 'faceted';
export const ORIGIN_LIST_NAME_AUTOCOMPLETE = 'autocomplete';

export const PRICE_RANGE_FILTER_VARIANT_RESULT = 'result';
export const PRICE_RANGE_FILTER_VARIANT_SEARCH = 'search';

// For mobile devices - icon with text, for tablet - icon, for desctop - icon with text
export const LOGO_VARIANT_PRIMARY = 'primary';
//Always icon
export const LOGO_VARIANT_SECONDARY = 'secondary';
//Always icon with text
export const LOGO_VARIANT_TERTIARY = 'tertiary';

export const LOGO_COLOR_PINK = 'pink_logo';
export const LOGO_COLOR_WHITE = 'white_logo';

export const NAVIGATION_MENU_THEME_LIGHT = 'light';
export const NAVIGATION_MENU_THEME_DARK = 'dark';

export const SEARCH_VARIANT_SKIN_CONCERNS = 'skinConcerns';
export const SEARCH_VARIANT_ACNE_ISSUES = 'acneIssues';
export const SEARCH_VARIANT_AGING_SIGNS = 'agingSings';
export const SEARCH_VARIANT_UNDER_EYE_CONCERNS = 'eye';

export const TIMEOT_FOR_CSS_TRANSITION = 300;

export const DEFAULT_SKIN_TYPE = 'Normal skin';
export const CATEGORY_EYE_CARE = 'Eye Care';
export const FALLBACK_FOR_CONCER_NORMAL_SKIN_WIHTOUT_EYE_CONCERNS = 'Under-eye skin';

//Modal window IDs for browser navigation backwards
export const MODAL_ID_GROW_DATABASE = 'suggest_product';
export const MODAL_ID_SKIN_PROFILE = 'skin_profile';
export const MODAL_ID_SKIN_TYPE = 'skin_type';

export const TYPE_PRODUCT_VARIANT_SEARCH = 'search';
export const TYPE_PRODUCT_VARIANT_ANALYSE = 'analyse';

export const INTERACTIVE_LIST_ITEM_AS_LI = 'li';
export const INTERACTIVE_LIST_ITEM_AS_SPAN = 'span';

export const MAX_INGREDIENTS_ANALYSIS_LENGTH = 2000;

export const ACTIVE_CONCERNS_VARIANT_ANALYSE = 'analyse';
export const ACTIVE_CONCERNS_VARIANT_SEARCH = 'search';

export const TIMER_SHOW_NOTIFICATION_ADD_TO_FAVORITE = 1500;
export const TIMER_SHOW_BANNER_FOR_PRODUCT = 7000;
export const MAX_TIMES_SHOW_NOTIFICATION_ADD_TO_FAVORITE = 3;

export const SIZE_URL_BAR = 70;

export const DEFAULT_SKIN_TYPE_FOR_WELCOME = 'Normal';
export const TIMER_SHOW_NOTIFICATION_FOR_SEARCH_RESULTS = 5000;

//A/B testing constants
export const EXPERIMENTS_LS_KEY = 'sbe';

const experiment_id = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'development':
      return '';
    case 'beta':
      return '3753f7ff8f57442ab7da991257768f29';
    //special case for Denis (don't touch)
    case 'dev':
      return 'ac7de8f2ae98414ba144d73168ba308e';
    default: //prod
      return '30e6c05e24434cd88d8f0e5cdc50fe37';
  }
};

export const LANDING_PAGE_EXPERIMENT_ID = experiment_id();

export const LEGAL_MODAL_VARIATN_TERMS = 'terms';

export const ACTIVE_COMPONENT_VARIANT_NOT_FOUND = 'notFound';
export const ACTIVE_COMPONENT_VARIANT_RESULTS = 'results';
export const ACTIVE_COMPONENT_VARIANT_SCAN = 'scan';

export const IS_MOBILE_DEVICE = isMobileDevice();

export const MIN_SCREEN_HEIGHT_FOR_DIFFERENT_PADDING_SCAN_PAGE = 760;

export const INFO_BUTTON_VARIANT_SMART_FILTER = 'smartfilter';
export const INFO_BUTTON_VARIANT_SKIN_PROFILE = 'skinprofile';
