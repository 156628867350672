import { useLocation } from 'react-router-dom';
import { useState, useEffect, useCallback, createContext, useContext, useRef } from 'react';

const ModalContext = createContext();

let hasInitializedModals = false;
const TIMEOUT_DURATION_FOR_CLOSE_ALL = 300;

export const ModalProvider = ({ children }) => {
  const [modals, setModals] = useState([]);
  const [isCloseAll, setIsCloseAll] = useState(false);
  const location = useLocation();
  const prevLocationRef = useRef(location.pathname);

  const closeAllModals = useCallback(
    (timeout = TIMEOUT_DURATION_FOR_CLOSE_ALL) => {
      setIsCloseAll(true);

      setTimeout(() => {
        setModals([]);
        document.body.classList.remove('no-scroll');
        setIsCloseAll(false);
      }, timeout);

      window.history.replaceState({}, '', location.pathname + location.search);
    },
    [location.pathname, location.search],
  );

  useEffect(() => {
    if (prevLocationRef.current !== location.pathname && modals.length > 0) {
      closeAllModals();
    }

    prevLocationRef.current = location.pathname;
  }, [closeAllModals, location, modals, setModals]);

  useEffect(() => {
    if (!hasInitializedModals) {
      hasInitializedModals = true;
      if (window.history.state?.modals) {
        window.history.replaceState({}, '', location.pathname + location.search);
        setModals([]);
      }
    }
  }, [setModals, location.pathname, location.search]);

  useEffect(() => {
    const handlePopstate = event => {
      if (prevLocationRef.current !== location.pathname) {
        setModals([]);
        document.body.classList.remove('no-scroll');
        return;
      }

      const currentModals = event.state?.modals || [];
      setModals(currentModals);
    };

    window.addEventListener('popstate', handlePopstate);
    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, [location.pathname]);

  useEffect(() => {
    if (modals.length > 0) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [modals]);

  useEffect(() => {
    return () => {
      setModals([]);
      document.body.classList.remove('no-scroll');
    };
  }, [location.pathname]);

  return (
    <ModalContext.Provider value={{ modals, setModals, closeAllModals, isCloseAll, setIsCloseAll }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useShowModalAndHideScroll = modalId => {
  const { modals, setModals, closeAllModals, isCloseAll } = useContext(ModalContext);
  const [isShowModal, setIsShowModal] = useState(false);

  useEffect(() => {
    if (isCloseAll) return;

    setIsShowModal(modals.includes(modalId));
  }, [modals, modalId, isCloseAll]);

  const openModal = useCallback(() => {
    setIsShowModal(true);
    const currentModalsInHistory = window.history.state?.modals || [];
    const newModals = [...currentModalsInHistory.filter(id => id !== modalId), modalId];

    const newState = {
      ...window.history.state,
      modals: newModals,
    };

    setModals(newModals);
    window.history.pushState(newState, '');
  }, [modalId, setModals]);

  const hideModal = useCallback(() => {
    setIsShowModal(false);
    const updatedModals = modals.filter(id => id !== modalId);

    const newState = {
      ...window.history.state,
      modals: updatedModals,
    };

    setModals(updatedModals);
    window.history.pushState(newState, '');
  }, [modalId, modals, setModals]);

  return { isShowModal, openModal, hideModal, closeAllModals, modals };
};
