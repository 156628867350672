import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { RouterProvider } from 'react-router-dom';
import './index.css';
import 'styles/common.scss';
import 'styles/animation.css';
import * as storage from 'services/localStorage';

// import ErrorBoundary from 'components/common/ErrorBoundary/ErrorBoundary';
import { EXPERIMENTS_LS_KEY } from 'services/constants';
import { router } from 'routes/AllRoutes';
import { LocalStorageProvider } from 'hooks/useLocalStorage';
import { PopupProvider } from 'hooks/usePopup';
import { SearchProductProvider } from 'hooks/useSearch';

// Activate experiments integration
const experiments = storage.get(EXPERIMENTS_LS_KEY);

if (!experiments) {
  storage.save(EXPERIMENTS_LS_KEY, window.sbe);
}

if (process.env.NODE_ENV === 'development') {
  import('mocks/browser')
    .then(({ worker }) => {
      worker.start({
        onUnhandledRequest: 'bypass',
      });
    })
    .catch(error => console.error('Error loading the worker:', error));
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <ErrorBoundary> */}
    <HelmetProvider>
      <LocalStorageProvider>
        <PopupProvider>
          <SearchProductProvider>
            <RouterProvider router={router} />
          </SearchProductProvider>
        </PopupProvider>
      </LocalStorageProvider>
    </HelmetProvider>
    {/* </ErrorBoundary> */}
  </React.StrictMode>,
);
