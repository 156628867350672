import { Helmet } from 'react-helmet-async';
import { routes } from 'routes/AllRoutes';
import { Suspense, useCallback, useEffect, useMemo } from 'react';
import { useLocation, useOutlet } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import EntryBanner from 'components/EntryBanner/EntryBanner';
import LoadingDisplay from 'components/LoadingDisplay/LoadingDisplay';
import Footer from 'components/Footer/Footer';

import { useLocalStorage } from 'hooks/useLocalStorage';

import { isPathMatch } from 'services/utilities';
import { SIZE_URL_BAR, TIMEOT_FOR_CSS_TRANSITION } from 'services/constants';
import { useIsAppleDevice } from 'hooks/useIsAppleDevice';

import s from './Layout.module.scss';

const pagesPathNeedFooter = [
  '/',
  '/product-search',
  '/smart-filter',
  '/results',
  '/favorites',
  '/analyse',
];

const pagesWithoutBanner = [
  '/skin-profile',
  '/privacy-policy',
  '/terms-of-service',
  '/scan-a-skincare-product',
];

const Layout = () => {
  const currentOutlet = useOutlet();
  const location = useLocation();
  const { isAcceptTermsAndConditions } = useLocalStorage();
  const isAppleDevice = useIsAppleDevice();
  const nodeRef = useMemo(() => {
    for (const route of routes) {
      if (route.path === '/*') continue;
      if (isPathMatch(route.path, location.pathname)) {
        return route.nodeRef;
      }
    }
    return routes.find(route => route.path === '/*')?.nodeRef;
  }, [location.pathname]);

  const handleAnimationStart = useCallback(() => {
    // URL bar can take up to 70px.

    window.scroll({
      top: isAppleDevice ? -SIZE_URL_BAR : 0,
      left: 0,
    });
  }, [isAppleDevice]);

  useEffect(() => {
    // Disable the browser's automatic scroll recovery, which it does before the animation starts
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }
  }, []);

  return (
    <>
      <Helmet>
        <meta property="og:title" content="Reveal What's Inside Your Skincare with SkinBuddy" />
        <meta
          property="og:description"
          content="Unlock the secrets of your skincare. SkinBuddy reveals the ingredients and matches you with products perfect for your skin."
        />
      </Helmet>

      <Suspense fallback={<LoadingDisplay />}>
        <SwitchTransition>
          <CSSTransition
            key={location.pathname}
            nodeRef={nodeRef}
            timeout={TIMEOT_FOR_CSS_TRANSITION}
            classNames="fade"
            mountOnEnter
            unmountOnExit
            onEnter={handleAnimationStart}
          >
            <div ref={nodeRef}>
              <div className={s.wrapper}>
                <div className={s.topSide}>
                  {!isAcceptTermsAndConditions &&
                    !pagesWithoutBanner.includes(location.pathname) && <EntryBanner />}
                  {currentOutlet}
                </div>

                {pagesPathNeedFooter.includes(location.pathname) && (
                  <Footer footerWrapper={s.footerWrapper} />
                )}
              </div>
            </div>
          </CSSTransition>
        </SwitchTransition>
      </Suspense>
    </>
  );
};

export default Layout;
