import {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import {
  ACNE_ISSUES,
  ADDITIONAL,
  AGING_SINGS,
  EYE_CONCERNS,
  PRICE_RANGE,
  SB_CATEGORIES,
  SKIN_CONCERNS,
  SKIN_TYPE,
} from 'data/search';
import { DEBOUNCED_SAVE_TO_LOCAL_STORAGE, LS_USER_DATA_KEY } from 'services/constants';
import * as storage from 'services/localStorage.js';
import { debounce } from 'throttle-debounce';

const initialMainStateSettings = {
  skinType: SKIN_TYPE,
  skinConcerns: SKIN_CONCERNS,
  acneIssues: ACNE_ISSUES,
  agingSings: AGING_SINGS,
  eye: EYE_CONCERNS,
};

const initialStateMainResults = {
  showcase: [],
  autocomplete: [],
};

const initialStateFacetedSearchSettings = {
  sbCategories: SB_CATEGORIES,
  priceRange: PRICE_RANGE,
  additional: ADDITIONAL,
};

const initialStateFilterOptionsForResults = {
  activeConcerns: [],
  activeLabels: [],
};

const initialStateIngredientsAnalysis = {
  concerns: [],
  resultSummary: null,
  category: '',
  ingredients_to_analyse: '',
};

const LocalStorageContext = createContext();

const data = storage.get(LS_USER_DATA_KEY);

const LocalStorageProvider = ({ children }) => {
  const saveToLocalStorageRef = useRef(
    debounce(DEBOUNCED_SAVE_TO_LOCAL_STORAGE, data => {
      storage.save(LS_USER_DATA_KEY, data);
    }),
  );

  const [isAcceptTermsAndConditions, setIsAcceptTermsAndConditions] = useState(() => {
    if (window.xb !== undefined) {
      return true;
    }

    if (!data) return false;

    return data?.isAcceptTermsAndConditions ?? true;
  });

  const [mainSettings, setMainSettings] = useState(() => {
    if (!data) {
      return initialMainStateSettings;
    }

    if (data?.main?.settings && !isAcceptTermsAndConditions) {
      return initialMainStateSettings;
    }

    return data?.main?.settings || initialMainStateSettings;
  });

  const [mainResults, setMainResults] = useState(
    () => data?.main?.results || initialStateMainResults,
  );

  const [facetedSearchSettings, setFacetedSearchSettings] = useState(() => {
    const settigs = data?.facetedSearch?.settings;

    if (!settigs) {
      return initialStateFacetedSearchSettings;
    }

    return { ...settigs, priceRange: PRICE_RANGE };
  });

  const [facetedResults, setFacetedResults] = useState(() => data?.facetedSearch?.results || []);
  const [originListName, setOriginListName] = useState(() => data?.originListName || '');

  const [favorites, setFavorites] = useState(() => data?.favorites || []);

  const [recentSearches, setRecentSearches] = useState(() => data?.recentSearches || []);
  const [timesShowAddedToFavoriteNotification, setTimesShowAddedToFavoriteNotification] = useState(
    () => data?.timesShowAddedToFavoriteNotification ?? 0,
  );
  const [isShowHelperProductPopup, setIsShowHelperProductPopup] = useState(
    () => data?.isShowHelperProductPopup ?? true,
  );

  const [filterOptions, setFilterOptions] = useState(
    () => data?.facetedSearch?.filter || initialStateFilterOptionsForResults,
  );

  const [ingredientsAnalysis, setIngredientsAnalysis] = useState(() => {
    if (!data) {
      return initialStateIngredientsAnalysis;
    }

    if (data?.ingredientsAnalysis && !isAcceptTermsAndConditions) {
      return initialStateIngredientsAnalysis;
    }

    return data?.ingredientsAnalysis || initialStateIngredientsAnalysis;
  });

  const [enteredIngredientsForAnalyse, setEnteredIngredientsForAnalyse] = useState('');
  const [selectedCategoryForAnalyse, setSelectedCategoryForAnalyse] = useState('');

  const changeMainSettings = useCallback(updatedData => {
    setMainSettings(prev => ({
      ...prev,
      ...updatedData,
    }));
  }, []);

  const updateFilterOptions = useCallback(updatedData => {
    setFilterOptions(prev => ({
      ...prev,
      ...updatedData,
    }));
  }, []);

  const changeFacetedSearchSettings = useCallback(updatedData => {
    setFacetedSearchSettings(prev => ({
      ...prev,
      ...updatedData,
    }));
  }, []);

  const changeAdditionalInFacetedSearch = useCallback((category_name, data) => {
    setFacetedSearchSettings(prev => ({
      ...prev,
      additional: {
        ...prev.additional,
        [category_name]: data,
      },
    }));
  }, []);

  const changeMainResults = useCallback(
    results =>
      setMainResults(prev => ({
        ...prev,
        ...results,
      })),
    [],
  );

  const addNewFavoriteProduct = useCallback(
    newProduct => setFavorites(prev => [newProduct, ...prev]),
    [],
  );

  const removeFavoriteProductById = useCallback(
    id => setFavorites(prev => prev.filter(el => el.id !== id)),
    [],
  );

  const isHaveFavoriteProductById = useCallback(
    id => favorites.some(el => el.id === id),
    [favorites],
  );

  const addRecentSearchQuery = useCallback(data => {
    setRecentSearches(prev => {
      const filteredPrev = prev.filter(query => query !== data);
      return [data, ...filteredPrev.slice(0, 14)];
    });
  }, []);

  const removeRecentSearchQuery = useCallback(queryContent => {
    setRecentSearches(prev => prev.filter(query => query !== queryContent));
  }, []);

  const resetRecentSearches = useCallback(() => {
    if (recentSearches.length === 0) return;
    setRecentSearches([]);
  }, [recentSearches.length]);

  const closeHelperProductPopup = useCallback(() => setIsShowHelperProductPopup(false), []);

  const changeIngredientsAnalysis = useCallback(
    ({ concerns, resultSummary, category, ingredients_to_analyse }) => {
      setIngredientsAnalysis({ concerns, resultSummary, category, ingredients_to_analyse });
    },
    [],
  );

  useEffect(() => {
    const saveToLocalStorage = saveToLocalStorageRef.current;

    const data = {
      isAcceptTermsAndConditions,
      main: {
        settings: mainSettings,
        results: mainResults,
      },
      facetedSearch: {
        settings: facetedSearchSettings,
        results: facetedResults,
        filter: filterOptions,
      },
      favorites,
      recentSearches,
      originListName,
      timesShowAddedToFavoriteNotification,
      isShowHelperProductPopup,
      ingredientsAnalysis,
    };

    saveToLocalStorage(data);
  }, [
    favorites,
    recentSearches,
    mainSettings,
    mainResults,
    facetedSearchSettings,
    facetedResults,
    originListName,
    saveToLocalStorageRef,
    timesShowAddedToFavoriteNotification,
    isShowHelperProductPopup,
    filterOptions,
    ingredientsAnalysis,
    isAcceptTermsAndConditions,
  ]);

  const contextValue = useMemo(
    () => ({
      isAcceptTermsAndConditions,
      setIsAcceptTermsAndConditions,
      mainSettings,
      changeMainSettings,
      mainResults,
      changeMainResults,
      facetedSearchSettings,
      changeFacetedSearchSettings,
      facetedResults,
      setFacetedResults,
      favorites,
      setFavorites,
      isHaveFavoriteProductById,
      addNewFavoriteProduct,
      removeFavoriteProductById,
      recentSearches,
      addRecentSearchQuery,
      removeRecentSearchQuery,
      resetRecentSearches,
      setOriginListName,
      originListName,
      timesShowAddedToFavoriteNotification,
      setTimesShowAddedToFavoriteNotification,
      isShowHelperProductPopup,
      closeHelperProductPopup,
      changeAdditionalInFacetedSearch,
      filterOptions,
      updateFilterOptions,
      ingredientsAnalysis,
      changeIngredientsAnalysis,
      enteredIngredientsForAnalyse,
      setEnteredIngredientsForAnalyse,
      selectedCategoryForAnalyse,
      setSelectedCategoryForAnalyse,
    }),
    [
      isAcceptTermsAndConditions,
      mainSettings,
      changeMainSettings,
      mainResults,
      changeMainResults,
      facetedSearchSettings,
      changeFacetedSearchSettings,
      facetedResults,
      favorites,
      isHaveFavoriteProductById,
      addNewFavoriteProduct,
      removeFavoriteProductById,
      recentSearches,
      addRecentSearchQuery,
      removeRecentSearchQuery,
      resetRecentSearches,
      originListName,
      timesShowAddedToFavoriteNotification,
      isShowHelperProductPopup,
      closeHelperProductPopup,
      changeAdditionalInFacetedSearch,
      filterOptions,
      updateFilterOptions,
      ingredientsAnalysis,
      changeIngredientsAnalysis,
      enteredIngredientsForAnalyse,
      selectedCategoryForAnalyse,
    ],
  );

  return (
    <LocalStorageContext.Provider value={contextValue}>{children}</LocalStorageContext.Provider>
  );
};

const useLocalStorage = () => {
  const context = useContext(LocalStorageContext);

  if (context === undefined) {
    throw new Error('useLocalStorage must be used within a LocalStorageProvider');
  }

  return context;
};

export { LocalStorageProvider, useLocalStorage };
